import React from "react"
import styled from "styled-components"
import InteractiveMap, { Marker } from "react-map-gl"

const MAPDiv = styled.div`
  width: ${props => (props.width ? props.width : "524px")};
  height: ${props => (props.height ? props.height : "420px")};
  background-color: white;
  /* border-radius: 12px; */
  /* padding: 12px; */
`

const Pin = ({ size = 20, onClick, fill }) => {
  return (
    <svg
      height={size}
      viewBox="0 0 24 24"
      style={{
        cursor: "pointer",
        fill,
        stroke: "none",
      }}
      onClick={onClick}
    >
      <path
        d="M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3
  c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9
  C20.1,15.8,20.2,15.8,20.2,15.7z"
      />
    </svg>
  )
}

const Mapper = props => {
  const [viewport, setViewport] = React.useState({
    latitude: 37.99019,
    longitude: 23.72839,
    zoom: 14,
    pitch: 0,
    bearing: 0,
  })

  return (
    <MAPDiv {...props}>
      <InteractiveMap
        // onClick, capture the pointer event so we can
        // get the lngLat of the click.
        // required for interactivity
        width="100%"
        height="100%"
        mapboxApiAccessToken="pk.eyJ1IjoiZ2lzZmVlZGJhY2siLCJhIjoiY2l2eDJndmtjMDFkeTJvcHM4YTNheXZtNyJ9.-HNJNch_WwLIAifPgzW2Ig"
        onViewportChange={viewport => setViewport(viewport)}
        {...viewport}
      >
        <Marker {...viewport}>
          <Pin onClick={() => {}} fill="#FF8275" />
        </Marker>
      </InteractiveMap>
    </MAPDiv>
  )
}

export default Mapper
