import styled from "styled-components"
const SubmitButton = styled.button`
  width: 166px;
  height: 40px;
  background: #ca998e;
  border: none;
  outline: none;

  &:hover {
    background: #001838;
    color: #fff;
  }
`
export default SubmitButton
