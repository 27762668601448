import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import styled from "styled-components"
import SubmitButton from "../components/common/SubmitButton"
import Mapper from "../components/common/Mapper"
import { HookedFormInput } from "../components/common/FormInput"
import Section from "../components/common/Section"
import { useForm } from "react-hook-form"
import { graphql } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"

const MapPanel = styled.div`
  background-color: white;
  min-height: 390px;
  height: 100%;
  box-shadow: 30px -14px 64px rgba(0, 47, 108, 0.1);
  border-radius: 12px;
  border: 12px solid white;
`
const InfoPanel = styled.div`
  border-radius: 12px;
  background-color: white;
  height: 100%;

  @media screen and (min-width: 992px) {
    min-height: 390px;
  }

  h5 {
    font-family: CeraGR-Medium;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
  }
  p {
    font-family: CeraGR-Regular;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }
`
const IndexPage = props => {
  const { register, handleSubmit } = useForm()
  const { t } = useTranslation()

  async function onSubmit(data) {
    await fetch(`/api/sendMail`, {
      method: "POST",
      body: {
        email: data.email,
        message: data.message,
        name: `${data.firstName} ${data.lastName}`,
      },
    })
  }

  return (
    <Layout location={props.location} type="primary">
      <Seo title={t("contact:title")} />
      <Section
        className="homehero-section"
        style={{ backgroundColor: "#f2f2f2" }}
      >
        <div className="container d-flex justify-content-center">
          <h1 style={{ margin: "72px auto" }}>{t("contact:title")}</h1>
        </div>
        <div className="container" style={{ height: "130px" }}></div>
      </Section>
      <Section
        className="homehero-section"
        style={{ transform: "translateY(-130px)" }}
      >
        <div className="container mb-5 map-container">
          <div id="1" className="map-container__map">
            <MapPanel>
              <Mapper id="map" width="100%" height="100%" />
            </MapPanel>
          </div>
          <div id="2" className="map-container__address">
            <InfoPanel className="p-4 shadow2">
              <div className="mb-3">
                <h5>{t("contact:address.name")}</h5>
                <p>{t("contact:address.value")}</p>
              </div>
              <div className="mb-3">
                <h5>{t("contact:call.name")}</h5>
                <p>210 3816 600</p>
              </div>
            </InfoPanel>
          </div>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="container p-5 bg-white shadow2">
            <div className="w-100">
              <h3 style={{ margin: "38px 0" }}>{t("contact:form.title")}</h3>
            </div>
            <div className="w-100 d-flex flex-wrap">
              <div className="col-lg-6 col-12 mb-4">
                <div className="m-2">
                  <p>{t("contact:form.name.name")}</p>
                  <HookedFormInput
                    name="firstName"
                    register={register}
                    placeholder={t("contact:form.name.placeholder")}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-12 mb-4">
                <div className="m-2">
                  <p>{t("contact:form.surname.name")}</p>
                  <HookedFormInput
                    placeholder={t("contact:form.surname.placeholder")}
                    name="lastName"
                    register={register}
                  />
                </div>
              </div>

              <div className="col-lg-6 col-12 mb-4">
                <div className="m-2">
                  <p>{t("contact:form.federation.name")}</p>
                  <HookedFormInput
                    placeholder={t("contact:form.federation.placeholder")}
                    name="federation"
                    register={register}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-12 mb-4">
                <div className="m-2">
                  <p>{t("contact:form.email.name")}</p>
                  <HookedFormInput
                    placeholder={t("contact:form.email.placeholder")}
                    name="email"
                    register={register}
                  />
                </div>
              </div>
              <div className="col-lg-12 col-12 mb-4">
                <div className="m-2">
                  <p>{t("contact:form.message.name")}</p>
                  <HookedFormInput
                    placeholder={t("contact:form.message.placeholder")}
                    name="message"
                    register={register}
                  />
                </div>
              </div>

              <div className="col-lg-12 col-12 mt-5 mb-5 text-align-end">
                <SubmitButton type="submit">{t("common:submit")}</SubmitButton>
              </div>
            </div>
          </div>
        </form>
      </Section>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query ContactQuery($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
